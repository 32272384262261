
import { defineComponent } from 'vue'
import { useFTLOrder } from '@/modules/FTLOrder/composable/useFTLOrder'

export default defineComponent({
    setup() {
        const { FTLOrderState } = useFTLOrder()
        return { FTLOrderState }
    },
})
