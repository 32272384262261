
import { defineComponent, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import FTLOrderHeader from '@/modules/FTLOrder/components/FTLOrderHeader/index.vue'
import FTLOrderBasic from '@/modules/FTLOrder/components/FTLOrderBasic/index.vue'
import FTLOrderAdditional from '@/modules/FTLOrder/components/FTLOrderAdditional/index.vue'
// import FTLOrderWaypoints from '@/modules/FTLOrder/components/FTLOrderWaypoints/index.vue'
import SPrintContent from '@/common/components/SPrintContent/index.vue'
import FTLInvoiceForPrint from '@/modules/Invoice/components/FTLInvoiceForPrint/index.vue'
import TTN from '@/modules/FTLOrder/components/TTN/index.vue'
import FTLOrderSender from '@/modules/FTLOrder/components/FTLOrderSender/index.vue'

import { useRoute } from 'vue-router'
import { useFTLOrder } from '@/modules/FTLOrder/composable/useFTLOrder'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'ftl-order-header': FTLOrderHeader,
        'ftl-order-basic': FTLOrderBasic,
        'ftl-order-additional': FTLOrderAdditional,
        // 'ftl-order-waypoints': FTLOrderWaypoints,
        's-print-content': SPrintContent,
        'ftl-invoice-for-print': FTLInvoiceForPrint,
        ttn: TTN,
        'ftl-order-sender': FTLOrderSender,
    },
    setup() {
        const route = useRoute()
        const orderId: string = route.params.order_id as string

        useTitle(`FTL заказ №${orderId}`)

        const notifications = useNotifications()

        const { FTLOrderState, loadFTLOrder, loadInvoiceForPrint, invoiceForPrintState } =
            useFTLOrder()

        const loadFTLOrderHandler = async () => {
            try {
                await loadFTLOrder(Number(orderId))
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения FTL заказа',
                    },
                    error
                )
            }
        }

        const printInvoice = async () => {
            if (!FTLOrderState.data) return

            try {
                await loadInvoiceForPrint(FTLOrderState.data.invoice_number)
                setTimeout(window.print, 500)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения FTL заказа для печати',
                    },
                    error
                )
            }
        }

        onMounted(() => {
            loadFTLOrderHandler()
        })

        return { FTLOrderState, printInvoice, invoiceForPrintState, orderId }
    },
})
