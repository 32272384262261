
import { defineComponent, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import LTLOrderHeader from '@/modules/LTLOrder/components/LTLOrderHeader/index.vue'
import InvoicesList from '@/modules/HistoryNew/components/InvoicesList/index.vue'
import SPagination from '@/common/components/SPagination/index.vue'

import InvoiceDocument from '@/modules/DocumentsModule/components/InvoiceDocument/index.vue'

import { useRoute } from 'vue-router'
import { useLTLOrder } from '@/modules/LTLOrder/composable/useLTLOrder'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'ltl-order-header': LTLOrderHeader,
        'invoices-list': InvoicesList,
        's-pagination': SPagination,
        'invoice-document': InvoiceDocument,
    },
    setup() {
        const route = useRoute()
        const orderId: string = route.params.order_id as string

        useTitle(`Заказ №${orderId}`)

        const notifications = useNotifications()

        const { LTLOrderState, loadLTLOrder, exportExcel } = useLTLOrder()

        const loadLTLOrderHandler = async (page: number) => {
            try {
                await loadLTLOrder(Number(orderId), page)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения LTL заказа',
                    },
                    error
                )
            }
        }

        const onExportExcel = () => {
            if (!orderId) return
            exportExcel(Number(orderId))
        }

        onMounted(() => {
            loadLTLOrderHandler(1)
        })

        const isEven = (n: number) => {
            return n % 2 === 0
        }

        return { LTLOrderState, loadLTLOrderHandler, isEven, onExportExcel }
    },
})
