
import { defineComponent } from 'vue'

import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

export default defineComponent({
    components: {
        's-button': SButton,
        'icon-close': iClose,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close', 'confirm'],
    setup(props, { emit }) {
        const emitClose = () => {
            if (props.loading) return
            emit('close')
        }

        const emitConfirm = () => {
            emit('confirm')
        }

        return { emitClose, emitConfirm }
    },
})
