<template>
    <div class="FTLOrderHeaderActions">
        <s-icon-button v-if="FTLOrderState.data.invoice_document" content="Скачать накладную на отпуск" @click="downloadInvoice">
            <icon-download />
        </s-icon-button>

        <router-link style="margin-left: 16px;" target="_blank" :to="`/waybill/${$route.params.order_id}/print`">
            <s-icon-button content="Распечатать путевой лист"> <icon-print /> </s-icon-button>
        </router-link>

        <s-icon-button content="Скачать накладную" :loading="is_print_invoices_loading" @click="printFtlOrderInvoices">
            <icon-download />
        </s-icon-button>

        <s-icon-button content="Отменить заказ" :loading="loading" @click="emitCancel">
            <icon-cancel />
        </s-icon-button>

        <!-- <s-icon-button content="Распечатать рейс" :loading="state.is_print_loading" @click="printRoute">
            <icon-print />
        </s-icon-button> -->

        <!-- <s-icon-button
            content="Распечатать"
            :loading="invoiceForPrintState.state.is_loading"
            @click="emitPrint"
        >
            <icon-print />
        </s-icon-button> -->
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'

import SIconButton from '@/common/components/SIconButton/index.vue'

// import iEdit from '@/assets/icons/Edit.svg'
import iCancel from '@/assets/icons/Cancel.svg'
import iPrint from '@/assets/icons/Print.svg'
import iDownload from '@/assets/icons/Download.svg'
import { useFTLOrder } from '../../composable/useFTLOrder'

import useNotifications from '@/common/composable/useNotifications'
import { useRoute } from 'vue-router'

import { FTLRealisation } from '@/core/realisations/FTL.realisation'

export default defineComponent({
    components: {
        's-icon-button': SIconButton,
        // 's-button': SButton,
        // 'icon-edit': iEdit,
        'icon-print': iPrint,
        'icon-cancel': iCancel,
        'icon-download': iDownload,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['cancel', 'print'],
    setup(_, { emit }) {
        const is_print_invoices_loading = ref(false)

        const ftl = new FTLRealisation()
        const route = useRoute()
        const notifications = useNotifications()

        const state = reactive({
            is_print_loading: false,
        })

        const emitCancel = () => {
            emit('cancel')
        }

        // const emitPrint = () => {
        //     emit('print')
        // }

        const saveFile = (content, file_name) => {
            const download_url = window.URL.createObjectURL(content)

            const link = document.createElement('a')
            link.href = download_url
            link.setAttribute('download', file_name)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }


        const printRoute = async () => {
            try {
                state.is_print_loading = true

                const order_id = Number(route.params.order_id)
                const response = await ftl.printRoute(order_id)
                saveFile(response, `FTL рейс №${order_id}.pdf`)
            } catch (error) {
                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При распечатке рейса произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                state.is_print_loading = false
            }
        }

        const { invoiceForPrintState, FTLOrderState } = useFTLOrder()

        const downloadFile = (url) => {
            const link = document.createElement('a')
            
            const url_parts = url.split('/')
            const file_name = url_parts[url_parts.length - 1]

            link.download = file_name
            link.href = url
            link.target = '_blank'

            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)

            link.remove()
        }

        const downloadInvoice = () => {
            if (!FTLOrderState.data) return
            downloadFile(FTLOrderState.data.invoice_document)
        }

        const printFtlOrderInvoices = async () => {
            if (is_print_invoices_loading.value) return

            try {
                is_print_invoices_loading.value = true

                const order_id = Number(route.params.order_id)
                const response = await ftl.printFtlOrderInvoices(order_id)

                saveFile(response, `Накладные FTL заказа ${order_id}.pdf`)
            } catch (error) {
                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При распечатке накладных произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                is_print_invoices_loading.value = false
            }
        }

        return { state, emitCancel, invoiceForPrintState, printRoute, downloadInvoice, FTLOrderState, is_print_invoices_loading, printFtlOrderInvoices }
    },
})
</script>

<style lang="scss" scoped src="./index.scss" />
