
import { defineComponent } from 'vue'

import FTLOrderBasicDelivery from '@/modules/FTLOrder/components/FTLOrderBasicDelivery/index.vue'
import FTLOrderBasicCar from '@/modules/FTLOrder/components/FTLOrderBasicCar/index.vue'

export default defineComponent({
    components: {
        'ftl-order-basic-delivery': FTLOrderBasicDelivery,
        'ftl-order-basic-car': FTLOrderBasicCar,
    },
})
