<template>
    <div class="InvoiceForPrint">
        <template v-if="!loading">
            <div
                class="InvoicesForPrint__invoice invoice"
                v-for="i in copiesCount"
                :key="`InvoiceForPrint-${i}`"
            >
                <header class="invoice-header">
                    <div class="invoice-header-block invoice-header-block--ordernum">
                        <div class="invoice-header-invoicenum">
                            <span class="invoice-header-invoicenum-caption">
                                Тапсырыс берушінің<br />
                                жүк құжат нөмірі/<br />
                                Номер накладной заказчика
                            </span>
                            <span class="invoice-header-invoicenum-value">
                                {{ invoice.dop_invoice_number }}
                            </span>
                        </div>
                        <div class="invoice-header-ordernum">
                            <span class="invoice-header-ordernum-value">
                                {{ invoice.order_number }}
                            </span>
                            <span class="invoice-header-ordernum-caption">
                                Тапсырыс нөмірі<br />Номер заказа
                            </span>
                        </div>
                    </div>
                    <div class="invoice-header-block invoice-header-block--barcode">
                        <div class="invoice-header-barcode-img">
                            <img
                                :id="'barcode_' + invoice.invoice_number"
                                class="barcode"
                                :data-value="invoice.invoice_number"
                                :src="`data:image/png;base64, ${invoice.barcode_html}`"
                                data-format="CODE39"
                                data-fontSize="32px"
                                :width="2"
                            />
                            <span>{{ invoice.invoice_number }}</span>
                        </div>
                        <span class="invoice-header-barcode-caption">
                            Жүк құжат нөмірі/Номер накладной
                        </span>
                    </div>
                    <div class="invoice-header-block invoice-header-block--logo">
                        <icon-logo />
                    </div>
                </header>

                <main class="invoice-content">
                    <h4 class="invoice-content-title">
                        Жөнелтішумен толтырылады. Барлық жолдар толтырылуға міндетті/Заполняется
                        Отправителем. Все поля обязательны к заполнению
                    </h4>
                    <div class="invoice-consignor">
                        <div class="invoice-content-inner">
                            <div class="invoice-line invoice-line--con-name">
                                <span class="invoice-label invoice-label--con-name"
                                    >Жіберуші/<br />Отправитель</span
                                >
                                <div class="invoice-slot invoice-slot--con-name">
                                    <span class="invoice-slot-value invoice-slot-value--conName">
                                        {{ invoice.sender.title }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-address">
                                <div class="invoice-address-column">
                                    <label> Адрес </label>
                                    <span>
                                        {{ invoice.sender.full_address }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--street" style="margin-top: 2px">
                                <div class="invoice-field invoice-field--building">
                                    <span class="invoice-label invoice-label--building">
                                        Үй/Ғимарат/<br />Дом/здание
                                    </span>
                                    <div class="invoice-slot invoice-slot--building">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.house }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="invoice-field invoice-field--office"
                                    style="margin-right: 7px"
                                >
                                    <span class="invoice-label invoice-label--office">
                                        Пәтер/Қенсе/<br />Кв/Офис
                                    </span>
                                    <div class="invoice-slot invoice-slot--office">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.office }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--index">
                                    <span class="invoice-label invoice-label--index">
                                        Индекс/<br />Индекс
                                    </span>
                                    <div class="invoice-slot invoice-slot--index">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.index }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-address">
                                <div class="invoice-address-column">
                                    <label> Комментарий </label>
                                    <span>
                                        {{ invoice.sender.comment }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--contact">
                                <div class="invoice-field invoice-field--contact-phone">
                                    <span class="invoice-label invoice-label--contact-phone">
                                        Байланыс телефоны/<br />Контактный телефон
                                    </span>
                                    <div class="invoice-slot invoice-slot--contact-phone">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.phone }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--contact-person">
                                    <span class="invoice-label invoice-label--contact-person">
                                        Байланысатын тұлға/<br />Контактное лицо
                                    </span>
                                    <div
                                        class="
                                            invoice-slot
                                            invoice-slot--contact-person
                                            invoice-slot--contact-person-left
                                        "
                                    >
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice--inner">
                            <div class="invoice-line invoice-line--cargo">
                                <div class="invoice-field invoice-field--places">
                                    <span class="invoice-label invoice-label--places">
                                        Орын саны(дана)/<br />Количество мест(шт)
                                    </span>
                                    <div class="invoice-slot invoice-slot--places">
                                        <span class="invoice-slot-value">
                                            {{ invoice.cargo_info.places }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--weight">
                                    <span class="invoice-label invoice-label--weight">
                                        Жүк салмағы(кг)/<br />Вес груза(кг)
                                    </span>
                                    <div class="invoice-slot invoice-slot--weight">
                                        <span class="invoice-slot-value">
                                            {{ invoice.cargo_info.weight }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--volume">
                                    <span class="invoice-label invoice-label--volume">
                                        Жүк көлемі(м<sup>3</sup>)/<br />
                                        Объем груза(м<sup>3</sup>)
                                    </span>
                                    <div class="invoice-slot invoice-slot--volume">
                                        <span class="invoice-slot-value">
                                            {{ invoice.cargo_info.volume }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-group invoice-group--payments">
                            <div class="invoice-group-column invoice-group-column--first">
                                <div class="invoice-field invoice-field--total-payment">
                                    <span class="invoice-label invoice-label--total-payment">
                                        <b>Төленетін сома/Сумма к оплате</b>
                                    </span>
                                    <div class="invoice-slot invoice-slot--total-payment">
                                        <span
                                            class="
                                                invoice-slot-value invoice-slot-value--total-payment
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="invoice-checkbox-group">
                                    <div class="invoice-checkbox">
                                        <div class="invoice-checkbox-slot">
                                            <icon-document-check
                                                v-if="invoice.payment_type === 'Отправителем'"
                                                class="invoice-checkbox-slot-check"
                                            />
                                        </div>
                                        <span class="invoice-checkbox-label">
                                            Төлем жөнелтуші/<br />Оплата отправителем
                                        </span>
                                    </div>
                                    <div class="invoice-checkbox">
                                        <div class="invoice-checkbox-slot">
                                            <icon-document-check
                                                v-if="invoice.payment_type === 'Получателем'"
                                                class="invoice-checkbox-slot-check"
                                            />
                                        </div>
                                        <span class="invoice-checkbox-label">
                                            Төлем алушы/<br />Оплата получателем
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-group-column invoice-group-column--second">
                                <div class="invoice-checkbox">
                                    <div class="invoice-checkbox-slot">
                                        <icon-document-check
                                            v-if="
                                                invoice.payment_method === 'Перечислением на счет'
                                            "
                                            class="invoice-checkbox-slot-check"
                                        />
                                    </div>
                                    <span class="invoice-checkbox-label">
                                        Шот бойынша аудару/<br />
                                        Перечислением по счету
                                    </span>
                                </div>
                                <div class="invoice-checkbox">
                                    <div class="invoice-checkbox-slot">
                                        <icon-document-check
                                            v-if="invoice.payment_method === 'Наличными'"
                                            class="invoice-checkbox-slot-check"
                                        />
                                    </div>
                                    <span class="invoice-checkbox-label">
                                        Қолма-қол ақшамен/<br />
                                        Наличными
                                    </span>
                                </div>
                                <div class="invoice-checkbox">
                                    <div class="invoice-checkbox-slot">
                                        <icon-document-check
                                            v-if="invoice.payment_method === 1"
                                            class="invoice-checkbox-slot-check"
                                        />
                                    </div>
                                    <span class="invoice-checkbox-label">
                                        Банк картасымен/<br />Банковской картой
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-group-column invoice-group-column--third">
                                <div class="invoice-field--pod">
                                    <span class="invoice-label invoice-label--pod">
                                        <b>Салынған төлем/<br />Наложенный платеж</b>
                                    </span>
                                    <div class="invoice-slot invoice-slot--pod">
                                        <span class="invoice-slot-value">
                                            {{ invoice.cargo_info.cod_payment }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--declared-value">
                                    <span class="invoice-label invoice-label--declared-value">
                                        Жарияланған құн/<br />Объявленная стоимость
                                    </span>
                                    <div class="invoice-slot invoice-slot--declared-value">
                                        <span class="invoice-slot-value">
                                            {{ invoice.cargo_info.declared_price }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-line invoice-line--annotation">
                            <span class="invoice-label invoice-label--annotation">
                                Жіберу бойынша ескертпе/<br />
                                Примечание по отправке
                            </span>
                            <div class="invoice-slot invoice-slot--annotation">
                                <span class="invoice-slot-value">
                                    {{ invoice.cargo_info.annotation }}
                                </span>
                            </div>
                        </div>
                        <div class="invoice--inner">
                            <div class="invoice-line invoice-line--signature">
                                <div class="invoice-field invoice-field--signature">
                                    <span class="invoice-label invoice-label--signature">
                                        Жіберуші қолы/<br />Подпись отправителя
                                    </span>
                                    <div class="invoice-slot invoice-slot--signature" />
                                </div>
                                <div class="invoice-field invoice-field--date">
                                    <span class="invoice-label invoice-label--date">
                                        Күні/<br />
                                        Дата
                                    </span>
                                    <div class="invoice-slot invoice-slot--date">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.date }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--time">
                                    <span class="invoice-label invoice-label--time">
                                        Уақыты/<br />
                                        Время
                                    </span>
                                    <div class="invoice-slot invoice-slot--time">
                                        <span class="invoice-slot-value">
                                            {{ invoice.sender.time }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--agreement">
                                <p>
                                    <sup>*</sup> Қолымды қою арқылы офертаның шарттарымен (жүк
                                    құжаттың артқы жағынан немесе www.spark.kz сайтында қараңыз)
                                    танысқанымды және олармен толық келісетінімді растаймын./
                                    Настоящей подписью подтверждаю, что я ознакомлен(а) и полностью
                                    согласен(а) с условиями оферты (см. на обратной стороне
                                    накладной или на сайте www.spark.kz).
                                </p>
                            </div>
                        </div>
                        <div class="invoice-block invoice-block--courier">
                            <h4 class="invoice-block-title">
                                Курьермен толтырылады. Барлық жолдарды толтыру міндетті/ Заполняется
                                Курьером. Все поля обязательны к заполнению
                            </h4>
                            <div class="invoice-line invoice-line--courier">
                                <div class="invoice-field invoice-field--courier">
                                    <span class="invoice-label invoice-label--courier">
                                        Алушы курьер/<br />
                                        Курьер забора
                                    </span>
                                    <div class="invoice-slot invoice-slot--courier" />
                                </div>
                                <div class="invoice-field invoice-field--date-courier">
                                    <span class="invoice-label invoice-label--date-courier">
                                        Күні/<br />
                                        Дата
                                    </span>
                                    <div class="invoice-slot invoice-slot--date-courier" />
                                </div>
                                <div class="invoice-field invoice-field--time-courier">
                                    <span class="invoice-label invoice-label--time-courier">
                                        Уақыты/<br />
                                        Время
                                    </span>
                                    <div class="invoice-slot invoice-slot--time-courier" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-receiver">
                        <div class="invoice-content-inner">
                            <div class="invoice-line invoice-line--con-name">
                                <span class="invoice-label invoice-label--con-name">
                                    Алушы/<br />
                                    Получатель
                                </span>
                                <div class="invoice-slot invoice-slot--con-name">
                                    <span class="invoice-slot-value invoice-slot-value--conName">
                                        {{ invoice.receiver.title }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-address">
                                <div class="invoice-address-column">
                                    <label> Адрес </label>
                                    <span>
                                        {{ invoice.receiver.full_address }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--street" style="margin-top: 2px">
                                <div class="invoice-field invoice-field--building">
                                    <span class="invoice-label invoice-label--building">
                                        Үй/Ғимарат/<br />
                                        Дом/здание
                                    </span>
                                    <div class="invoice-slot invoice-slot--building">
                                        <span class="invoice-slot-value">
                                            {{ invoice.receiver.house }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="invoice-field invoice-field--office"
                                    style="margin-right: 7px"
                                >
                                    <span class="invoice-label invoice-label--office">
                                        Пәтер/Қенсе/<br />
                                        Кв/Офис
                                    </span>
                                    <div class="invoice-slot invoice-slot--office">
                                        <span class="invoice-slot-value">
                                            {{ invoice.receiver.office }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--index">
                                    <span class="invoice-label invoice-label--index">
                                        Индекс/<br />
                                        Индекс
                                    </span>
                                    <div class="invoice-slot invoice-slot--index">
                                        <span class="invoice-slot-value">
                                            {{ invoice.receiver.index }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-address">
                                <div class="invoice-address-column">
                                    <label> Комментарий </label>
                                    <span>
                                        {{ invoice.receiver.comment }}
                                    </span>
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--contact">
                                <div class="invoice-field invoice-field--contact-phone">
                                    <span class="invoice-label invoice-label--contact-phone">
                                        Байланыс телефоны/<br />Контактный телефон
                                    </span>
                                    <div class="invoice-slot invoice-slot--contact-phone">
                                        <span class="invoice-slot-value">
                                            {{ invoice.receiver.phone }}
                                        </span>
                                    </div>
                                </div>
                                <div class="invoice-field invoice-field--contact-person">
                                    <span class="invoice-label invoice-label--contact-person">
                                        Байланысатын тұлға/<br />
                                        Контактное лицо
                                    </span>
                                    <div class="invoice-slot invoice-slot--contact-person">
                                        <span class="invoice-slot-value">
                                            {{ invoice.receiver.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-block invoice-block--confirmation">
                            <h4 class="invoice-block-subtitle invoice-block-subtitle--confirmation">
                                Алушы Толтырады. Барлық жолдарды толтыру міндетті/ Заполняется
                                Получателем. Все поля обязательны к заполнению
                            </h4>
                            <h4 class="invoice-block-title invoice-block-title--confirmation">
                                Жеткізуді растау/ Подтверждение доставки
                            </h4>
                            <div class="invoice-line invoice-line--receiver">
                                <div class="invoice-field invoice-field--receiver">
                                    <span class="invoice-label invoice-label--receiver">
                                        Алушы (Т.А.Ә)/<br />Получатель (Ф.И.О.)
                                    </span>
                                    <div class="invoice-slot invoice-slot--receiver" />
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--received">
                                <div class="invoice-field invoice-field--received">
                                    <span class="invoice-label invoice-label--received">
                                        Алынған (орындар саны)/<br />
                                        Получено (количество мест)
                                    </span>
                                    <div class="invoice-slot invoice-slot--received" />
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--annotation-received">
                                <div class="invoice-field invoice-field--annotation-received">
                                    <span class="invoice-label invoice-label--annotation-received">
                                        Жеткізу бойынша ескертпе/<br />
                                        Примечания по доставке
                                    </span>
                                    <div class="invoice-slot invoice-slot--annotation-received" />
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--signature-received">
                                <div class="invoice-field invoice-field--signature-received">
                                    <span class="invoice-label invoice-label--signature-received">
                                        Қолы/<br />
                                        Подпись
                                    </span>
                                    <div class="invoice-slot invoice-slot--signature-received" />
                                </div>
                                <div class="invoice-field invoice-field--date-received">
                                    <span class="invoice-label invoice-label--date-received">
                                        Күні/<br />
                                        Дата
                                    </span>
                                    <div class="invoice-slot invoice-slot--date-received" />
                                </div>
                                <div class="invoice-field invoice-field--time-received">
                                    <span class="invoice-label invoice-label--time-received">
                                        Уақыты/<br />
                                        Время
                                    </span>
                                    <div class="invoice-slot invoice-slot--time-received" />
                                </div>
                            </div>
                            <div class="invoice-line invoice-line--agreement-received">
                                <p>
                                    <sup>*</sup> Жөнелтімді толық көлемде алғанымды, «Spark
                                    Logistics» ЖШС-не шағымдарым жоқ екенін өз қолыммен растаймын. /
                                    Своей подписью подтверждаю, что отправление получено в полном
                                    объеме, претензий к ТОО "Spark Логистика" не имею.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-block invoice-block--operator">
                        <h4 class="invoice-block-title invoice-block-title--operator">
                            Терминал Операторымен толтырылады. Барлық жолдарды толтыру міндетті/
                            Заполняется Оператором терминала. Все поля обязательны к заполнению
                        </h4>
                        <div class="invoice-line invoice-line--operator-places">
                            <div class="invoice-field invoice-field--operator-places">
                                <span class="invoice-label invoice-label--operator-places"
                                    >Орындар саны (қабылданды)/<br />Количество мест (принято)</span
                                >
                                <div class="invoice-slot invoice-slot--operator-places" />
                            </div>

                            <div class="invoice-field invoice-field--qr">
                                <vue-qrcode
                                    :value="`https://spark.kz?invoice=${invoice.invoice_number}`"
                                    :options="{ width: 70 }"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="invoice-block invoice-block--information">
                        <ul class="invoice-block-list">
                            <li class="invoice-block-list-item">Алматы +7 (727) 345-00-75</li>
                            <li class="invoice-block-list-item">Нур-Султан +7 (7172) 27-74-74</li>
                            <li class="invoice-block-list-item">Актобе +7 (7132) 41-07-43</li>
                            <li class="invoice-block-list-item">Караганда +7 771 104-44-05</li>
                            <li class="invoice-block-list-item">Шымкент +7 771 104-44-02</li>
                            <li class="invoice-block-list-item">support@spark.kz</li>
                            <li class="invoice-block-list-item">www.spark.kz</li>
                        </ul>
                    </div>
                </main>
            </div>
        </template>

        <template v-else> loading... </template>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import iLogo from '@/assets/icons/Logo.svg'
import iDocumentCheck from '@/assets/icons/DocumentCheck.svg'

import VueQrcode from '@chenfengyuan/vue-qrcode'

export default defineComponent({
    components: {
        'icon-logo': iLogo,
        'icon-document-check': iDocumentCheck,
        'vue-qrcode': VueQrcode,
    },
    props: {
        invoice: {},
        loading: {
            type: Boolean,
            default: false,
        },
        copiesCount: {
            type: Number,
            default: 2,
        },
    },
})
</script>

<style lang="scss" scoped src="./index.scss" />
