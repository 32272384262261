
import { defineComponent } from 'vue'

import SButton from '@/common/components/SButton/index.vue'

import iGreenLayer from '@/assets/icons/GreenLayer.svg'

export default defineComponent({
    components: {
        's-button': SButton,
        'icon-green-layer': iGreenLayer,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        cost: {
            type: String,
            default: '0',
        },
    },
    emits: ['confirm', 'cancel'],
    setup(_, { emit }) {
        const emitConfirm = () => {
            emit('confirm')
        }

        const emitCancel = () => {
            emit('cancel')
        }

        return { emitConfirm, emitCancel }
    },
})
