import { reactive, ref } from 'vue'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { FullLTLOrderT } from '@/core/types/LTL.types'
import useNotifications from '@/common/composable/useNotifications'
import { useFile } from '@/common/composable/useFile'

type LTLOrderStateType = {
    state: {
        is_loading: boolean
        is_excel_loading: boolean
    }
    data: FullLTLOrderT | null
    data_print?: FullLTLOrderT | null
    pagination: {
        current_page: number
        total_pages: number
    }
}

const LTLOrderState: LTLOrderStateType = reactive({
    state: {
        is_loading: false,
        is_excel_loading: false,
    },
    data: null,
    data_print: null,
    pagination: {
        current_page: 1,
        total_pages: 1,
    },
})

const isCancelLoading = ref(false)

export const useLTLOrder = () => {
    const invoicesRealisation = new InvoicesRealisation()
    const notifications = useNotifications()
    const { saveFile } = useFile()

    const loadLTLOrder = async (order_id: number, page: number) => {
        LTLOrderState.pagination.current_page = page

        try {
            LTLOrderState.state.is_loading = true

            const orderResponse = await invoicesRealisation.getLTLOrder(order_id, {
                page,
                isExtended: 1,
                limit: 9999,
            })

            LTLOrderState.data = orderResponse.data
            LTLOrderState.pagination.total_pages = orderResponse.meta.last_page
        } catch (error) {
            console.error(error)
        } finally {
            LTLOrderState.state.is_loading = false
        }
    }

    const loadLTLOrderForPrint = async (order_id: number) => {
        try {
            LTLOrderState.state.is_loading = true

            const orderResponse = await invoicesRealisation.getLTLOrderForPrint(order_id)

            LTLOrderState.data_print = orderResponse.data
        } catch (error) {
            console.error(error)
        } finally {
            LTLOrderState.state.is_loading = false
        }
    }

    const cancelLTLOrder = async (order_id: number) => {
        try {
            isCancelLoading.value = true
            await invoicesRealisation.cancelLTLOrder(order_id)
        } catch (error) {
            console.error(error)
        } finally {
            isCancelLoading.value = false
        }
    }

    const exportExcel = async (orderId: number) => {
        if (!orderId) return

        try {
            LTLOrderState.state.is_excel_loading = true

            const response = await invoicesRealisation.exportExcelLTLOrderId(orderId)
            saveFile(response, 'LTL Заказ')
        } catch (error) {
            console.error(error)
            notifications.failure(
                {
                    group: 'create-module',
                    type: 'error',
                    title: 'Ошибка скачивания Excel',
                },
                error
            )
        } finally {
            LTLOrderState.state.is_excel_loading = false
        }
    }

    return {
        loadLTLOrder,
        loadLTLOrderForPrint,
        LTLOrderState,
        isCancelLoading,
        cancelLTLOrder,
        exportExcel,
    }
}
