import { reactive, ref } from 'vue'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { FTLOrderT } from '@/core/types/FTL.types'
import { FTLInvoiceForPrintResponseT } from '@/core/types/Responses/Invoices.responses'

type FTLOrderStateType = {
    state: {
        is_loading: boolean
    }
    data: FTLOrderT | null
}

const FTLOrderState: FTLOrderStateType = reactive({
    state: {
        is_loading: false,
    },
    data: null,
})

const FTLOrderConfirmationState = reactive({
    state: {
        is_loading: false,
    },
})

const invoiceForPrintState = reactive({
    state: {
        is_loading: false,
    },
    data: null as null | FTLInvoiceForPrintResponseT,
})

export const useFTLOrder = () => {
    const invoicesRealisation = new InvoicesRealisation()

    const loadFTLOrder = async (order_id: number) => {
        try {
            FTLOrderState.state.is_loading = true
            const orderResponse = await invoicesRealisation.getFTLOrder(order_id)
            FTLOrderState.data = orderResponse.data
        } finally {
            FTLOrderState.state.is_loading = false
        }
    }

    const cancelFTLOrder = async (order_id: number) => {
        try {
            FTLOrderConfirmationState.state.is_loading = true
            await invoicesRealisation.cancelFTLOrder(order_id)
        } finally {
            FTLOrderConfirmationState.state.is_loading = false
        }
    }

    const confirmFTLOrder = async (order_id: number) => {
        try {
            FTLOrderConfirmationState.state.is_loading = true
            await invoicesRealisation.confirmFTLOrder(order_id)
        } finally {
            FTLOrderConfirmationState.state.is_loading = false
        }
    }

    const loadInvoiceForPrint = async (invoice_number: string) => {
        try {
            invoiceForPrintState.state.is_loading = true
            const response = await invoicesRealisation.getFTLInvoiceForPrint(invoice_number)
            invoiceForPrintState.data = response
        } catch (error) {
            console.error(error)
        } finally {
            invoiceForPrintState.state.is_loading = false
        }
    }

    return {
        loadFTLOrder,
        FTLOrderState,
        FTLOrderConfirmationState,
        cancelFTLOrder,
        confirmFTLOrder,
        loadInvoiceForPrint,
        invoiceForPrintState,
    }
}
