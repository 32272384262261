
import { defineComponent } from 'vue'

import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'
import LTLOrderHeaderActions from '@/modules/LTLOrder/components/LTLOrderHeaderActions/index.vue'

import iArrowLeft from '@/assets/icons/ArrowLeft.svg'

import { useRoute } from 'vue-router'

export default defineComponent({
    components: {
        's-bread-crumbs': SBreadCrumbs,
        'ltl-order-header-actions': LTLOrderHeaderActions,
        'icon-arrow-left': iArrowLeft,
    },
    props: {
        orderId: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const route = useRoute()
        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'LTL накладные',
                path: '/history',
            },
            {
                id: 2,
                name: props.orderId,
                path: `/order/ltl/${route.params.order_id}`,
            },
        ]

        return { breadcrumbsLinks }
    },
})
