
import { computed, defineComponent, ref } from 'vue'

import SIconButton from '@/common/components/SIconButton/index.vue'

import SModal from '@/common/components/SModal/index.vue'
import CancelOrder from '@/modules/LTLOrder/components/CancelOrder/index.vue'

import iBigPlus from '@/assets/icons/BigPlus.svg'
import iCancel from '@/assets/icons/Cancel.svg'
import iPrint from '@/assets/icons/Print.svg'

import { useLTLOrder } from '@/modules/LTLOrder/composable/useLTLOrder'
import { useRoute } from 'vue-router'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-icon-button': SIconButton,
        's-modal': SModal,
        'cancel-order': CancelOrder,
        'icon-big-plus': iBigPlus,
        'icon-print': iPrint,
        'icon-cancel': iCancel,
    },
    setup() {
        const notifications = useNotifications()

        const route = useRoute()
        const orderId = route.params.order_id as string

        const { isCancelLoading, loadLTLOrderForPrint, cancelLTLOrder, LTLOrderState } =
            useLTLOrder()

        const isCancelModalOpened = ref(false)

        const closeModal = () => {
            isCancelModalOpened.value = false
        }

        const openModal = () => {
            isCancelModalOpened.value = true
        }

        const printInvoices = async () => {
            if (!orderId) return

            try {
                await loadLTLOrderForPrint(Number(orderId))
                setTimeout(window.print, 1000)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка при печати накладных',
                    },
                    error
                )
            }
        }

        const cancelOrderHandler = async () => {
            try {
                await cancelLTLOrder(Number(orderId))
                closeModal()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка отмены LTL заказа',
                    },
                    error
                )
            }
        }

        const isCanEditOrder = computed(() => {
            if (!LTLOrderState.data) return false

            if (LTLOrderState.data.length > 0) {
                // Груз забран
                if (LTLOrderState.data.some((invoice) => invoice.status_code === 203)) {
                    return false
                }

                return true
            }

            return false
        })

        const isCanCancelOrder = computed(() => {
            if (!LTLOrderState.data) return false

            if (LTLOrderState.data.length > 0) {
                // Груз создан или Забирается курьером
                if (
                    LTLOrderState.data.every(
                        (invoice) => invoice.status_code === 201 || invoice.status_code === 202
                    )
                ) {
                    return true
                }

                return false
            }

            return false
        })

        return {
            printInvoices,
            isCancelLoading,
            closeModal,
            isCancelModalOpened,
            openModal,
            cancelOrderHandler,
            isCanEditOrder,
            isCanCancelOrder,
        }
    },
})
