
import { defineComponent, PropType, ref } from 'vue'

import SButton from '@/common/components/SButton/index.vue'

import { FTLOrderStockDeliveryNoteT } from '@/core/types/FTL.types'
import { FTLRealisation } from '@/core/realisations/FTL.realisation'

import useNotifications from '@/common/composable/useNotifications'

export default defineComponent({
    components: {
        's-button': SButton,
    },
    props: {
        orderId: {
            type: Number,
            default: null
        },
        items: {
            type: Array as PropType<FTLOrderStockDeliveryNoteT[]>,
            default: () => [],
        },
    },
    setup(props) {
        const notifications = useNotifications()
        const is_print_invoices_loading = ref(false)

        const ftl = new FTLRealisation()

        const saveFile = (content: Blob | File, file_name: string) => {
            const download_url = window.URL.createObjectURL(content)

            const link = document.createElement('a')
            link.href = download_url
            link.setAttribute('download', file_name)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

        const printFtlOrderStockDeliveryNote = async (stock_delivery_note_index: number) => {
            if (is_print_invoices_loading.value) return

            try {
                is_print_invoices_loading.value = true

                const response = await ftl.printFtlOrderStockDeliveryNoteInvoice(props.orderId, stock_delivery_note_index)
                saveFile(response, `FTL накладная заказа №${props.orderId} пункт ${stock_delivery_note_index + 1}.pdf`)
            } catch (error) {
                console.error(error)

                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При распечатке накладной произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                is_print_invoices_loading.value = false
            }
        }

        return {
            is_print_invoices_loading,
            printFtlOrderStockDeliveryNote
        }
    }
})
