
import { defineComponent } from 'vue'
import { POINTS_TOKENS } from '@/core/constants/common.constants'

import FTLOrderStatus from '@/common/components/FTLOrderStatus/index.vue'
import { useFTLOrder } from '@/modules/FTLOrder/composable/useFTLOrder'

export default defineComponent({
    components: { 'ftl-order-status': FTLOrderStatus },
    setup() {
        const { FTLOrderState } = useFTLOrder()
        return { POINTS_TOKENS, FTLOrderState }
    },
})
