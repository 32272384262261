
import { defineComponent } from 'vue'

import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'
import FTLOrderConfirmation from '@/modules/FTLOrder/components/FTLOrderConfirmation/index.vue'
import FTLOrderHeaderActions from '@/modules/FTLOrder/components/FTLOrderHeaderActions/index.vue'

import iArrowLeft from '@/assets/icons/ArrowLeft.svg'
import { useRoute } from 'vue-router'
import { useFTLOrder } from '../../composable/useFTLOrder'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { FTL_STATUS_WAIT_CONFIRM_TOKEN } from '@/core/constants/FTL.constants'

export default defineComponent({
    components: {
        's-bread-crumbs': SBreadCrumbs,
        'ftl-order-confirmation': FTLOrderConfirmation,
        'ftl-order-header-actions': FTLOrderHeaderActions,
        'icon-arrow-left': iArrowLeft,
    },
    props: {
        orderId: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const notifications = useNotifications()

        const route = useRoute()
        const orderId = route.params.order_id as string

        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'FTL заказы',
                path: '/history',
            },
            {
                id: 2,
                name: props.orderId,
                path: `/order/ftl/${orderId}`,
            },
        ]

        const {
            FTLOrderState,
            FTLOrderConfirmationState,
            cancelFTLOrder,
            confirmFTLOrder,
            loadFTLOrder,
        } = useFTLOrder()

        const confirmFTLOrderHandler = async () => {
            try {
                await confirmFTLOrder(Number(orderId))

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'success',
                    title: 'FTL заказ подтверждён',
                })

                loadFTLOrder(Number(orderId))
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка подтверждения FTL заказа',
                    },
                    error
                )
            }
        }

        const cancelFTLOrderHandler = async () => {
            const answer = confirm('Вы действительно хотите отменить заказ?')
            if (!answer) return

            try {
                await cancelFTLOrder(Number(orderId))

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'success',
                    title: 'FTL заказ отменён',
                })

                loadFTLOrder(Number(orderId))
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка отмены FTL заказа',
                    },
                    error
                )
            }
        }

        const emitPrint = () => {
            emit('print')
        }

        return {
            breadcrumbsLinks,
            FTLOrderState,
            FTLOrderConfirmationState,
            confirmFTLOrderHandler,
            cancelFTLOrderHandler,
            FTL_STATUS_WAIT_CONFIRM_TOKEN,
            emitPrint,
        }
    },
})
