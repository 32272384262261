<template>
    <div class="FTLOrderSender">
        <h2 class="FTLOrderSender__title">Отправной пункт</h2>

        <div class="FTLOrderSender__body">
            <div class="body__block">
                <p class="block__subtext">Наименование</p>
                <p class="block__text">{{ sender.title }}</p>
            </div>

            <div class="body__block">
                <p class="block__subtext">Контактное лицо</p>
                <p class="block__text">{{ sender.full_name }} {{ toPhone(sender.phone) }}</p>
            </div>

            <div class="body__block">
                <p class="block__subtext">Адрес отправителя</p>
                <p class="block__text">{{ sender.full_address }}</p>
            </div>

            <div v-if="additionalService.length">
                <h2 class="FTLOrderSender__title">Доп. услуги</h2>

                <div class="body__services">
                    <div
                        class="body__service"
                        v-for="(service, i) in additionalService"
                        :key="`service-${i}`"
                    >
                        <p class="block__subtext">{{ service.type_name }}</p>
                        <p class="block__text">Да</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        sender: {},
        additionalService: [],
    },
})
</script>

<style lang="scss" scoped src="./index.scss" />
